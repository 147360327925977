const site = {
	core: 'web47',
	env: document.querySelector('#env_buscador').value,
	lang: window.location.hostname.substring(window.location.hostname.length - 2, window.location.hostname.length) === 'pt' ? 'pt' : 'es',
	sources: {
		cdn: document.querySelector('#cdn_buscador').value,
		url: document.querySelector('#url_buscador').value,
	},
	tag: document.querySelector('#site_buscador').value,
};

const imageExtensions = ['apng', 'avif', 'bmp', 'cur', 'gif', 'ico', 'jfif', 'jpeg', 'jpg', 'pjp', 'pjpeg', 'png', 'svg', 'tif', 'tiff', 'webp'];

const changeExtension = (source, extension = '.gif') => {
	source = source.replace(/\s/g, '');

	if (extension !== undefined) {
		if (!extension.startsWith('.')) extension = '.' + extension;

		const slashIndex = source.lastIndexOf('/');
		const dotIndex = source.indexOf('.', slashIndex ? slashIndex : 0);
		const questionIndex = source.indexOf('?', dotIndex ? dotIndex : slashIndex ? slashIndex : 0);

		if (dotIndex > 0) {
			if (imageExtensions.includes(source.substring(dotIndex + 1, questionIndex < 0 ? source.length : questionIndex))) {
				if (questionIndex > 0) return source.slice(0, dotIndex) + extension + source.slice(source.indexOf('?'));
				return source.slice(0, dotIndex) + extension;
			}
		} else {
			if (questionIndex > 0) return source.slice(0, questionIndex) + extension + source.slice(source.indexOf('?'));
			return source + extension;
		}
	} else if (source.includes('http')) {
		//FIXME: Arreglar esto, porque no es la misma ruta https, hay que echarle una vuelta.
		const parts = source.split('/');

		if (parts.length >= 4) {
			return [parts[0], parts[1], parts[2]].join('/') + '/static/img/no_img.jpg';
		} else {
			return '/static/img/no_img.jpg';
		}
	} else return '/static/img/no_img.jpg';
};

const controlUrl = (url = '', origin = null) => {
	let source = url;

	if (origin && !url.includes('http')) {
		if (url.startsWith('/')) source = `${origin}${url}`;
		else source = `${origin}/${url}`;

		if (source.includes('n~')) {
			source = source.replace(/n~/g, 'ni');
		}
	} else if (site.env === 'DEV' && url.startsWith('/') && !url.includes('static') && !origin) {
		source = 'https://dev.elmotorista.es' + source;
	} else if (site.env === 'DEV' && !url.startsWith('http') && !url.startsWith('/')) {
		source = 'https://dev.elmotorista.es/' + source;
	} else if (site.env === 'DEV' && !origin && url.includes('https://elmotorista.es')) {
		source = url.replace('elmotorista.es', 'dev.elmotorista.es');
	}

	return `${source}`;
};

export { site, changeExtension, controlUrl };
